// import $ from 'jquery';
import Swiper from 'swiper/bundle';
// import 'swiper/css';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import VanillaTilt from 'vanilla-tilt';
import remodal from 'remodal';
// gsap scrollTrigerr
gsap.registerPlugin(ScrollTrigger);

const isSmartPhone = () => {
  if (window.matchMedia && window.matchMedia('(max-device-width: 768px)').matches) {
    return true;
  } else {
    return false;
  }
};



/**
 * 現在一から上スクロールでクラスふよ
 */
class ScrollDownToggleClass {
  constructor(target, border = 56) {
    this.target = target;
    this.border = border;
    this.offset = 0;
    this.lastPosition = 0;
    this.ticking = false;
  }
  toggleClass() {
    this.lastPosition = window.scrollY;
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        if (this.lastPosition > this.border) {
          if (this.lastPosition > this.offset) {
            this.target.classList.add('is-hide');
          } else {
            this.target.classList.remove('is-hide');
          }
          this.offset = this.lastPosition;
        }
        this.ticking = false;
      });
      this.ticking = true;
    }
  }
}
const header = document.querySelector(".js-header");
const headerOpen = document.querySelector(".js-header-open");
const scrollDownToggleClass = new ScrollDownToggleClass(header);
window.addEventListener('scroll', () => {
  if (!headerOpen.checked) {
    scrollDownToggleClass.toggleClass()
  }
});
/**
 * @desc スクロールであるポイントを超えたらクラスを付与
 * @param {*} targetEl  
 * @param {*} togglePointEl 
 * @param {*} toggleClass 
 */
const scrollInToggleClass = (targetEl, togglePointEl, toggleClass) => {

  if (togglePointEl) {
    const observer1 = new IntersectionObserver((entries) => {
      for (const e of entries) {
        if (e.isIntersecting) {
          targetEl.classList.remove(toggleClass);
        } else {
          targetEl.classList.add(toggleClass);
        }
      }
    });
    observer1.observe(togglePointEl);
  } else {
    if (targetEl) {
      targetEl.classList.add(toggleClass);
    }
  }
};


scrollInToggleClass(header, document.querySelector(".js-hero"), "is-active")
// scrollInToggleClass(document.querySelector(".js-header-fresh"), document.querySelector(".js-hero"), "is-active")

/**
 * @desc 一文字づつ文字をラップする
 * @param {*} target 
 * @returns 一文字つづspanでラップしたテキスト
 */
const spanWrapText = (target) => {
  const nodes = [...target.childNodes];
  let returnText = '';

  for (const node of nodes) {
    if (node.nodeType == 3) {
      const text = node.textContent.replace(/\r?\n/g, '');
      const splitText = text.split('');
      for (const char of splitText) {
        returnText += `<span>${char}</span>`;
      }
    } else {
      returnText += node.outerHTML;
    }
  }
  return returnText;
};

const splitTexts = document.querySelectorAll('.js-splittext')
if (splitTexts) {
  splitTexts.forEach((splitText) => {
    splitText.innerHTML = spanWrapText(splitText);
  })
}


/* */

// const hero = document.querySelector(".js-hero")
// if (hero) {
//   const heroTl = gsap.timeline();
//   heroTl.from(hero.querySelector('.hero__mv img'), {
//     scale: 1.2,
//     duration: 1.75
//   });
//   gsap.fromTo(header, {
//     y: "-100%",
//     opacity: 0
//   }, {
//     duration: 1.5,
//     opacity: 1,
//     y: 0
//   });
//   gsap.fromTo(hero.querySelector('.hero__main picture'), {
//     opacity: 0,
//     y: 50,
//   }, {
//     duration: 1.7,
//     y: 0,
//     opacity: 1,
//   });
// }

// const pageHero = document.querySelector('.js-page-hero');
// if (pageHero) {
//   const pageHeroTl = gsap.timeline();
//   gsap.set(pageHero.querySelector('.c-hero__title-ruby'), {
//     opacity: 0
//   });
//   gsap.set(pageHero.querySelector('.c-hero__title'), {
//     opacity: 0
//   });
//   pageHeroTl.from(pageHero.querySelector('img'), {
//     opacity: 0,
//     scale: 1.25,
//     duration: .8
//   });
//   pageHeroTl.to(pageHero.querySelector('.c-hero__title-ruby'), {
//     delay: 0,
//     opacity: 1,
//   });
//   pageHeroTl.to(pageHero.querySelector('.c-hero__title'), {
//     opacity: 1,
//   });

// }
const fadeInEls = gsap.utils.toArray('.js-fadein-effect1');
if (fadeInEls.length != 0) {
  fadeInEls.forEach((box, index) => {
    gsap.fromTo(box, {
      y: 30,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      duration: 1.25,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const scaleEffect = gsap.utils.toArray('.js-scale-effect1');
if (scaleEffect.length != 0) {
  scaleEffect.forEach((box, index) => {
    gsap.fromTo(box, {
      y: 60,
      scale: 1.25,
      opacity: 0,
    }, {
      y: 0,
      scale: 1,
      opacity: 1,
      duration: 1.5,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const rollEffect = gsap.utils.toArray('.js-x-effect1');
if (rollEffect.length != 0) {
  rollEffect.forEach((box, index) => {
    gsap.fromTo(box, {
      x: "25%",
    }, {
      x: 0,
      duration: .75,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const rollEffect2 = gsap.utils.toArray('.js-x-effect2');
if (rollEffect2.length != 0) {
  rollEffect2.forEach((box, index) => {
    gsap.fromTo(box, {
      x: "-25%",
    }, {
      x: 0,
      duration: .75,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const yEffect = gsap.utils.toArray('.js-y-effect1');
if (yEffect.length != 0) {
  yEffect.forEach((box, index) => {
    gsap.fromTo(box, {
      y: "25%",
    }, {
      y: 0,
      duration: .75,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const yEffect2 = gsap.utils.toArray('.js-y-effect2');
if (yEffect2.length != 0) {
  yEffect2.forEach((box, index) => {
    gsap.fromTo(box, {
      y: "-25%",
    }, {
      y: 0,
      duration: .75,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const staggerEffect1 = gsap.utils.toArray('.js-stagger-effect1');
if (staggerEffect1.length != 0) {
  // staggerEffect1.forEach((box, index) => {
  gsap.fromTo(staggerEffect1, {
    y: 50,
    opacity: 0,
  }, {
    y: 0,
    opacity: 1,
    stagger: 0.1,
    duration: .75,
    scrollTrigger: {
      trigger: staggerEffect1,
      start: 'top bottom',
      end: 'bottom top',
    }
  });
  // });
}
const staggerEffect2 = gsap.utils.toArray('.js-stagger-effect2');
if (staggerEffect2.length != 0) {
  // staggerEffect2.forEach((box, index) => {
  gsap.fromTo(staggerEffect2, {
    y: 70,
    opacity: 0,
  }, {
    y: 0,
    opacity: 1,
    stagger: 0.25,
    duration: .75,
    scrollTrigger: {
      trigger: staggerEffect2,
      start: 'top bottom',
      end: 'bottom top',
    }
  });
  // });
}
const staggerEffect3 = gsap.utils.toArray('.js-stagger-effect3');
if (staggerEffect3.length != 0) {
  gsap.fromTo(staggerEffect3, {
    y: -150,
    opacity: 0,
  }, {
    y: 0,
    opacity: 1,
    stagger: .25,
    duration: .75,
    scrollTrigger: {
      trigger: staggerEffect3,
      start: 'top bottom',
      end: 'bottom top',
    }
  });
}
const staggerEffect4 = gsap.utils.toArray('.js-stagger-effect4');
if (staggerEffect4.length != 0) {
  gsap.fromTo(staggerEffect4, {
    y: -150,
    opacity: 0,
  }, {
    y: 0,
    opacity: 1,
    stagger: .25,
    duration: .75,
    scrollTrigger: {
      trigger: staggerEffect4,
      start: 'top bottom',
      end: 'bottom top',
    }
  });
}

// tl.from(spans, {
//   opacity: 0,
//   duration: 0.01,
//   stagger: 0.08,
// });



// const scrollDownToggleClass = new ScrollDownToggleClass(document.getElementById('header'));
// window.addEventListener('scroll', () => {
//   scrollDownToggleClass.toggleClass()
// });



/****/
//

// mv animation

// $(function(){

//   $('.js-toggle').on('click', function(){
//     $(this).toggleClass('is-active')
//     $(this).next().slideToggle()
//   })
// })

// (function(){
//   const heroMv = document.querySelector('.hero__mv')
//   const count = 400; 
//   for (let i = 0 ; i < 400; i++) {
//     let block = document.createElement('div')
//     block.classList.add('box')
//     heroMv.appendChild(block)
//   }
//   const boxs = gsap.utils.toArray('.box');
//   if (boxs) {
//     gsap.from(boxs, {
//       // delay.05
//       top: 80,
//       left: 80,
//       stagger: .005,
//       // scale : 150
//       // y: 10,
//       // opacity: 1,
//     })
//   }
// })()



// gsap.set(document.querySelector(".js-mov"), {perspective:200});
// gsap.from(document.querySelector(".js-mov"), .05, {css:{autoAlpha:0}}, {css:{autoAlpha:1}, immediateRender:true});
// gsap.fromTo(document.querySelector(".js-mov"), {
//   transform: "rotate3d(-40, 10, 5, 75deg)"
// }, {
//   transform: "none",
//   duration: 3
// })
// gsap.from(document.querySelector(".js-mov"), {
//   rotateZ: "80",
//   skewY: -40,
//   // skewX: 40,
//   x: -10,
//   // y: -90,
//   scale: 1.05,
//   duration: 1.2
// });
// gsap.from(document.querySelector(".js-mov1"), {
//   rotate: "-305",
//   scale: .5,
//   duration: 1.2
// });
// gsap.from(document.querySelector(".js-mov2"), {
//   rotate: "-105",
//   skewY: 30,
//   x: 20,
//   skewX: 20,
//   scale: .5,
//   duration: 1.2
// });


(function (gsap) {
  const hero = document.querySelector('.js-hero');
  const mv = document.querySelector('.js-mv__image');
  if (hero && mv) {
    gsap.to(mv, {
      duration: 2,
      y: -200,
      scrollTrigger: {
        trigger: hero,
        start: "top center",
        scrub: 1,
      }
    })
  }
  const aboutLogo = document.querySelector('.js-about-logo');
  if (aboutLogo) {
    gsap.to(aboutLogo, {
      duration: 2,
      x: -100,
      scrollTrigger: {
        trigger: aboutLogo,
        start: "top center",
        scrub: 1,
      }
    })
  }

  const sectionTitleElement = gsap.utils.toArray('.js-enter-active');
  if (sectionTitleElement) {
    let animationStart = 'top center';
    if (isSmartPhone()) {
      animationStart = 'top bottom';
    }
    sectionTitleElement.forEach((element, index) => {
      gsap.to(element, {
        scrollTrigger: {
          trigger: element,
          start: animationStart,
          onEnter: () => element.classList.add('is-active'),
        }
      });
    });
  }



  /* bg-animtion */

}(gsap))


/** */

let spSwiper1 = null;
let spSwiper1El = document.querySelector('.js-sp-swiper1');
let spSwiper2 = null;
let spSwiper2El = document.querySelector('.js-sp-swiper2');
let spSwiper3 = null;
let spSwiper3El = document.querySelector('.js-sp-swiper3');

let swiperbool = false;
const initSwiperSp = () => {
  if (spSwiper1El && spSwiper1El.querySelectorAll('.swiper-slide').length != 0) {
    spSwiper1 = new Swiper(spSwiper1El, {
      centeredSlides: true,
      slidesPerView: 1,
      loop: false,
      speed: 1000,
      spaceBetween: 15,
      loopedSlides: spSwiper1El.querySelectorAll('.swiper-slide').length,
      navigation: {
        nextEl: '.js-sp-swiper1-next',
        prevEl: '.js-sp-swiper1-prev',
      },
      pagination: {
        clickable: true,
        el: document.querySelector('.js-sp-swiper-pagination')
      },
    });
    spSwiper1.on('slideChange', function () {
      const scrollhint = spSwiper1El.querySelector('.scroll-hint-icon-wrap')
      if (scrollhint) {
        const triggerEvent = new Event('scroll')
        spSwiper1El.dispatchEvent(triggerEvent)
      }
    });

  }
  if (spSwiper2El && spSwiper2El.querySelectorAll('.swiper-slide').length != 0) {
    spSwiper2 = new Swiper(spSwiper2El, {
      centeredSlides: true,
      slidesPerView: 'auto',
      loop: true,
      speed: 1000,
      loopedSlides: spSwiper2El.querySelectorAll('.swiper-slide').length,
    });
    spSwiper2.on('slideChange', function () {
      const scrollhint = spSwiper2El.querySelector('.scroll-hint-icon-wrap')
      if (scrollhint) {
        const triggerEvent = new Event('scroll')
        spSwiper2El.dispatchEvent(triggerEvent)
      }
    });
  }

  if (spSwiper3El && spSwiper3El.querySelectorAll('.swiper-slide').length != 0) {
    spSwiper3 = new Swiper(spSwiper3El, {
      centeredSlides: true,
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      loopedSlides: spSwiper3El.querySelectorAll('.swiper-slide').length,
      navigation: {
        nextEl: '.js-sp-swiper3-next',
        prevEl: '.js-sp-swiper3-prev',
      },
    });
  }
};

let timeoutID = 0;
let delay = 500;
$(window).on('resize', function () {
  clearTimeout(timeoutID);
  timeoutID = setTimeout(() => {
    // メディアクエリにマッチしたらスライダーを初期化
    if (isSmartPhone() && !swiperbool) {
      swiperbool = true;
      initSwiperSp();
    } else if (!isSmartPhone() && swiperbool) {
      swiperbool = false;
      // メディアクエリにマッチせず、スライダーが初期化済みだったらスライダーを解除
      if (spSwiper1) {
        spSwiper1.destroy(false, true);
      }
      if (spSwiper2) {
        spSwiper2.destroy(false, true);
      }
      if (spSwiper3) {
        spSwiper3.destroy(false, true);
      }
    }
  }, delay);
});

window.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector('.js-sp-swiper1')) {
    if (isSmartPhone()) {
      initSwiperSp();
      swiperbool = true;
    } else {
      swiperbool = false;
    }
  }

  const hero = document.querySelector(".js-hero-slider")
  if (hero) {
    let apconfig = isSmartPhone() ? false : {
      delay: 4000, // 4秒後に次のスライドへ
      disableOnInteraction: false, // ユーザーが操作しても自動再生を継続
    };
    const heroSlider = new Swiper(hero, {
      loop: true,
      loopedSlides: hero.querySelectorAll('.swiper-slide').length,
      speed: 1000,
      slidesPerView: 'auto',
      // autoplay: apconfig,
      navigation: {
        nextEl: ".js-hero-slider-next.swiper-button-next",
        prevEl: ".js-hero-slider-prev.swiper-button-prev"
      },
      breakpoints: {
        768: {
          slidesPerView: 'auto',
        }
      }
    });
    heroSlider.on('slideChange', function () {
      const scrollhint = hero.querySelector('.scroll-hint-icon-wrap')
      if (scrollhint) {
        const triggerEvent = new Event('scroll')
        hero.dispatchEvent(triggerEvent)
      }
    });
  }
  const newsList = document.querySelector(".js-news-slider")
  if (newsList) {
    let apconfig = {
      delay: 4000, // 4秒後に次のスライドへ
      disableOnInteraction: false, // ユーザーが操作しても自動再生を継続
    };
    const newsListSlider = new Swiper(newsList, {
      loop: true,
      spaceBetween: 50,
      loopedSlides: newsList.querySelectorAll('.swiper-slide').length,
      speed: 1000,
      slidesPerView: 'auto',
      // autoplay: apconfig,
      navigation: {
        nextEl: newsList.querySelector(".swiper-button-next"),
        prevEl: newsList.querySelector(".swiper-button-prev")
      },
    });
  }

  const planList = document.querySelector(".js-plan-slider")
  if (planList) {
    // let planconfig = {
    //   delay: 4000, // 4秒後に次のスライドへ
    //   disableOnInteraction: false, // ユーザーが操作しても自動再生を継続
    // };
    const planListSlider = new Swiper(planList, {
      loop: true,
      spaceBetween: 50,
      loopedSlides: planList.querySelectorAll('.swiper-slide').length,
      speed: 1000,
      slidesPerView: 'auto',
      // autoplay: apconfig,
      // navigation: {
      //   nextEl: document.querySelector(".js-plan-slider-next.swiper-button-next"),
      //   prevEl: document.querySelector(".js-plan-slider-prev.swiper-button-prev")
      // },
    });
  }

  VanillaTilt.init(document.querySelectorAll(".js-vtilte"), {
    max: 20,
    speed: 800,
    perspective: 1000,
    glare: true,
    "max-glare": 1,
  });
});


// let
//   cursor = document.getElementById("cursor"),
//   follower = document.getElementById("follower");
// let
//   pos = { x: 0, y: 0 }, // カーソル要素の座標
//   mouse = { x: pos.x, y: pos.y }, // マウスカーソルの座標
//   speed = 0.8; // 0.01〜1 数値が大きほど早い

// // カーソルの座標を設定する
// let cursorSetX = gsap.quickSetter(cursor, "x", "px");
// let cursorSetY = gsap.quickSetter(cursor, "y", "px");

// // 遅延するカーソルの座標を設定する
// let followerSetX = gsap.quickSetter(follower, "x", "px");
// let followerSetY = gsap.quickSetter(follower, "y", "px");

// // マウスカーソル座標を取得する
// document.addEventListener("mousemove", function (event) {
//   mouse.x = event.pageX;
//   mouse.y = event.pageY;
// });


// gsap.ticker.add(function () {

//   let dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

//   pos.x += (mouse.x - pos.x) * dt;
//   pos.y += (mouse.y - pos.y) * dt;
//   cursorSetX(pos.x);
//   cursorSetY(pos.y);
//   followerSetX(pos.x);
//   followerSetY(pos.y);
// });

// $("a").on({
//   "mouseenter": function () {
//     $(cursor).addClass("is-active");
//     $(follower).addClass("is-active");
//   },
//   "mouseleave": function () {
//     $(cursor).removeClass("is-active");
//     $(follower).removeClass("is-active");
//   }
// });


const scrollAccordion = () => {

  const containar = document.querySelector(".js-scroll-accordions");
  const scrollAccordions = document.querySelectorAll(".js-scroll-accordion");
  const scrollAccordionFirst = document.querySelector(".js-scroll-accordion.is-first");
  const scrollAccordionLast = document.querySelector(".js-scroll-accordion.is-last");
  if (!containar || !scrollAccordions) {
    return;
  }
  let mm = gsap.matchMedia();

  mm.add("(min-width: 768px)", () => {


    const scrollScrub = 1.5;
    gsap.set(scrollAccordions, { height: 0 });
    gsap.set(scrollAccordionFirst, { height: "auto" });
    gsap.from(".js-acrcordion-progress", {
      scaleX: 0,
      transformOrigin: "left center",
      ease: "none",
      scrollTrigger: {
        trigger: containar,
        scrub: scrollScrub,
        start: "top top",
        // end: "bottom top",
        end: "+=4000",

      }
    })
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: containar,
        scrub: scrollScrub,
        start: "top top",
        end: "+=4000",
        // snap: 1,
        // markers: true,
        pin: true
      }
    });
    let animationOptions1 = {
      height: "auto",
      duration: 1,
      delay: 0.05,
      opacity: 1,
      ease: "none",
    };
    let animationOptions2 = {
      height: 0,
      duration: .5,
      opacity: 0,
      delay: .5,
      ease: "none",
    };

    // gsap.to(".progress", {
    //   value: 100,
    //   ease: "none",
    //   scrollTrigger: { scrub: 0.3 }
    // });

    timeline
      .to(scrollAccordions[0], animationOptions2)
      // .to(scrollAccordions[1], animationOptions1)
      .to(scrollAccordions[1], animationOptions1, '<')
      .to(scrollAccordions[1], animationOptions2)
      // .to(scrollAccordions[2], animationOptions1)
      .to(scrollAccordions[2], animationOptions1, '<')
    // gsap.set(scrollAccordionFirst, { height : "auto" });
  });


};
scrollAccordion()
// scrollAccordionTl.to('.c-knowLedge_list_item01 .-dtl', {
// 	height: 0,
// 	paddingBottom: 0,
// 	opacity: 0,
// 	delay: 0.6,
// })
// .from('.c-knowLedge_list_item02 .-dtl', {
// 	height: 0,
// 	paddingBottom: 0,
// 	opacity: 0,
// }, '<')
// .from('.c-knowLedge_img_item02', {
// 	y: 550,
// }, '<')
// .to('.c-knowLedge_list_item02 .-dtl', {
// 	height: 0,
// 	paddingBottom: 0,
// 	opacity: 0,
// 	delay: 0.6,
// })
// .from('.c-knowLedge_list_item03 .-dtl', {
// 	height: 0,
// 	paddingBottom: 0,
// 	opacity: 0,
// }, '<')
// .from('.c-knowLedge_img_item03', {
//   y: 550,
// }, '<');